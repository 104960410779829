import * as React from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Parser from 'html-react-parser'

// Components
import { Container, Typography } from '@mui/material'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import BackgroundImgCard from '../../components/BackgroundImgCard'
import BackgroundCardIspo from '../../components/BackgroundCardIspo'
import BenefitsTable from '../../components/BenefitsTable'
import CriteriasTable from '../../components/CriteriasTable'
import JuryCard from '../../components/JuryCard'
import Slider from '../../components/Slider'

// Content
import { apparelContent } from '../../content/apparelEdition'

// Icons
import arrowBack from '../../assets/images/icons/arrow-back-left.svg'

// Styling
import '../../styles/ispo.scss'

const ApparelPage = () => {
  const { t } = useTranslation()
  return (
    <Layout hideStructure={true}>
      <Container maxWidth='xl' disableGutters className='pt-s-48 pb-s-48 mb-s-32'>
        <div className='breadcrumbs-list'>
          <p className='body-2 text-grey-7'>
            <a href='/ispo-textrends-award' className='d-flex flex-row align-items-center'>
              <img src={arrowBack} height={18} alt={'Arrow Back...'} className='pe-s-16' />
              {t('BACK')}
            </a>
          </p>
          <p className='body-1 pt-s-16'>
            <span className='text-grey-7'>{t('ISPO_TEXTRENDS_AWARD')} / </span>
            <span className='fw-bold'>{t('APPAREL_EDITION')}</span>
          </p>
        </div>
      </Container>
      <div className='container-lg text-center text-blue-3 text-container'>
        <b className='sub-headline text-grey-7'>{t('HOW_IT_WORKS')}</b>
        <h1 className='heading-1 pt-s-8'>{t('APPAREL_EDITION')}</h1>
        <h5 className='body-1 pt-s-16 fw-bold'>{t('ISPO_TEXTRENDS_AWARD')}</h5>
        <h5 className='body-1 pt-s-16 fw-normal'>{t('APPAREL_EDITION_TEXT_1')}</h5>
        <h5 className='body-1 pb-s-12 fw-normal'>{t('APPAREL_EDITION_TEXT_2')}</h5>
      </div>
      <Container maxWidth='xl' disableGutters sx={{ textAlign: 'center' }} className='pt-s-48 pb-s-48'>
        <div className='container-xl text-center text-blue-3 text-container big'>
          <Typography variant='h2' sx={{ color: 'bluishGrey.ten', mb: 4 }}>
            {t('MEET_THE_JURY_MEMBERS')}
          </Typography>
          <Typography variant='body1' sx={{ color: 'bluishGrey.ten', mb: 4 }}>
            {t('MEET_THE_JURY_MEMBERS_TEXT')}
          </Typography>
        </div>
        <Slider content={apparelContent(t).carousel}>
          <JuryCard />
        </Slider>
      </Container>
      <Container maxWidth='xl' disableGutters sx={{ textAlign: 'center' }} className='pb-s-24 mb-s-32'>
        <div className='container-xl text-center text-blue-3 text-container big'>
          <h1 className='heading-2'>{t('CATEGORIES')}</h1>
          <p className='body-1 pb-s-24 pt-s-12'>{t('APPAREL_CATEGORIES_TEXT')}</p>
        </div>
        <Slider content={apparelContent(t).slider}>
          <BackgroundCardIspo />
        </Slider>
      </Container>
      <Container maxWidth='xl' disableGutters sx={{ textAlign: 'center' }} className='pb-s-24 mb-s-32'>
        <div className='container-xl text-center text-blue-3 text-container big'>
          <h1 className='heading-2'>{t('APPAREL_CROSS_CATEGORIES')}</h1>
          <p className='body-1 pt-s-12'>{t('APPAREL_CROSS_CATEGORIES_TEXT')}</p>
        </div>
        <div className='pb-s-80 pt-s-32'>
          <div className='d-lg-none d-flex'>
            <Slider content={apparelContent(t).crossCategorySlider}>
              <BackgroundCardIspo />
            </Slider>
          </div>
          <div className='d-flex d-lg-flex d-none gap-5 cross-category-section'>
            {apparelContent(t).crossCategorySlider.slides.map((crossCategory, i) => (
              <div key={i} className='card-cc d-flex justify-content-center align-items-center'>
                <div className='cross-category-card'>
                  <img src={crossCategory.background} alt={crossCategory.title} />
                  <div className='text-bottom pt-s-12 pb-s-12 ps-s-16 pe-s-16 text-start'>
                    <Typography
                      variant='body2Bold'
                      my={1}
                      sx={{
                        transition: 'all 250ms cubic-bezier(0.4,0,0.2,1)',
                      }}
                    >
                      {crossCategory.caption}
                    </Typography>
                  </div>
                </div>
                <p className='text-start body-2 ps-s-24'>{crossCategory.description}</p>
              </div>
            ))}
          </div>
        </div>
      </Container>
      <Container maxWidth='xl' disableGutters sx={{ textAlign: 'center' }} className='pb-s-48 mb-s-32'>
        <div className='container-xl text-center text-blue-3 text-container big'>
          <h1 className='heading-2'>{t('CRITERIA')}</h1>
          <p className='body-1 fw-bold pt-s-12'>{t('APPAREL_CRITERIA_TEXT')}</p>
        </div>
        <div className='pb-s-80 pt-s-32'>
          <CriteriasTable content={apparelContent(t).fourCriteria} />
          <p className='body-2 pt-s-32 pb-s-20 text-start'>{Parser(t('APPAREL_CRITERIA_TEXT_1'))}</p>
          <CriteriasTable content={apparelContent(t).holisticCriteria} />
          <p className='body-2 pt-s-32 pb-s-20 text-start'>{Parser(t('APPAREL_CRITERIA_TEXT_2'))}</p>
          <CriteriasTable content={apparelContent(t).smartCriteria} />
        </div>
      </Container>
      <Container maxWidth='xl' disableGutters sx={{ textAlign: 'center' }} className='mb-s-32'>
        <div className='container-xl text-center text-blue-3 text-container big'>
          <h1 className='heading-2'>{t('BENEFITS_COSTS')}</h1>
          <p className='body-1 fw-bold pt-s-12'>{t('BENEFITS_COSTS_TEXT')}</p>
        </div>
        <div className='pb-s-80 pt-s-32'>
          <BenefitsTable content={apparelContent(t).benefitsTable} />
        </div>
      </Container>
      <Container
        maxWidth='xl'
        disableGutters
        sx={{ textAlign: 'center' }}
        className='key-dates-section pb-s-32 mb-s-32 px-0'
      >
        <BackgroundImgCard content={apparelContent(t).applyBrochure} />
      </Container>
    </Layout>
  )
}
export const Head = ({ location }) => (
  <Seo
    title='Apparel Edition - ISPO Textrends Awards'
    description='The ISPO Textrends Award - Apparel Edition celebrates innovative ready-made garments and accessories in the sport and outdoor sector.'
    pathname={location.pathname}
  />
)
export const query = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
export default ApparelPage
