import juryApparel1 from '../assets/images/ispo/jury-apparel/nora-kuhner.jpg'
import juryApparel5 from '../assets/images/ispo/jury-apparel/frank-kempe.jpg'
import juryApparel7 from '../assets/images/ispo/jury-apparel/kate-kallaway.jpg'
import categoryApparel1 from '../assets/images/ispo/categories-apparel/t-shirts-product-image-01.jpg'
import categoryApparel2 from '../assets/images/ispo/categories-apparel/pullovers-product-image-01.jpg'
import categoryApparel3 from '../assets/images/ispo/categories-apparel/dresses-and-skirts-product-image-01.jpg'
import categoryApparel4 from '../assets/images/ispo/categories-apparel/shirts-and-blouses-product-image-01.jpg'
import categoryApparel5 from '../assets/images/ispo/categories-apparel/pants-and-tights-product-image-01.jpg'
import categoryApparel6 from '../assets/images/ispo/categories-apparel/jackets-and-vests-product-image-01.jpg'
import categoryApparel7 from '../assets/images/ispo/categories-apparel/underwear-and-swimwear-product-image-01.jpg'
import categoryApparel8 from '../assets/images/ispo/categories-apparel/accessories-and-product-image-01.jpg'
import crossCategoryApparel1 from '../assets/images/ispo/categories-apparel/a6-upcycledcap-for-teams-lab-03.jpg'
import crossCategoryApparel2 from '../assets/images/ispo/categories-apparel/sm-hero-suits-addelm-co-ltd-02.jpg'
import howToApply from '../assets/images/ispo/how-to-apply-background-image.jpg'
export const apparelContent = (t) => {
  return {
    carousel: {
      className: 'swiper-juri swiper-center swiper-navigation-green',
      slidesPerView: 'auto',
      spaceBetween: 24,
      simulateTouch: true,
      pagination: {
        clickable: true,
        dynamicBullets: true,
        renderBullet: function (index, className) {
          return `<span class="${className}"><span class="swiper-pagination-bullet-default"></span></span>`
        },
      },
      autoplay: {
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
      },
      navigation: true,
      slides: [
        {
          name: t('JURI_APPAREL_7'),
          job: t('JURI_APPAREL_7_JOB'),
          img: juryApparel7,
          info: t('JURI_APPAREL_7_DESCRIPTION'),
          topImage: t('JURI_APPAREL_7_EXPERTISE'),
        },
        {
          name: t('JURI_APPAREL_1'),
          job: t('JURI_APPAREL_1_JOB'),
          img: juryApparel1,
          info: t('JURI_APPAREL_1_DESCRIPTION'),
          topImage: t('JURI_APPAREL_1_EXPERTISE'),
        },
        {
          name: t('JURI_APPAREL_5'),
          job: t('JURI_APPAREL_5_JOB'),
          img: juryApparel5,
          info: t('JURI_APPAREL_5_DESCRIPTION'),
          topImage: t('JURI_APPAREL_5_EXPERTISE'),
        },
      ],
    },
    applyBrochure: {
      title: t('HOW_TO_APPLY'),
      text: t('HOW_TO_APPLY_STEPS_APPAREL'),
      subhead: t('APPLICATIONS_ARE_OPEN_APPAREL'),
      bgImg: false,
      backgroundImage: howToApply,
      extraClass: 'apply-container',
      buttons: [
        {
          text: t('DISCOVER_BROCHURE'),
          href: 'https://s3.eu-central-1.amazonaws.com/static-prod-fra.foursource.com/public/files/ISPO_TT_FallWinter_2526.pdf',
          color: 'white',
          variant: 'outlined',
          target: '_blank',
          rel: 'noreferrer',
        },
      ],
    },
    slider: {
      className: 'swiper-cols-4 swiper-navigation-green',
      slidesPerView: 'auto',
      spaceBetween: 24,
      simulateTouch: true,
      pagination: {
        clickable: true,
        dynamicBullets: true,
        renderBullet: function (index, className) {
          return `<span class="${className}"><span class="swiper-pagination-bullet-default"></span></span>`
        },
      },
      loop: true,
      autoplay: {
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
      },
      navigation: true,
      slides: [
        {
          background: categoryApparel1,
          link: `${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup`,
          caption: t('APPAREL_CATEGORY_1'),
          description: t('APPAREL_CATEGORY_1_DESCRIPTION'),
        },
        {
          background: categoryApparel2,
          link: `${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup`,
          caption: t('APPAREL_CATEGORY_2'),
          description: t('APPAREL_CATEGORY_2_DESCRIPTION'),
        },
        {
          background: categoryApparel3,
          link: `${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup`,
          caption: t('APPAREL_CATEGORY_3'),
          description: t('APPAREL_CATEGORY_3_DESCRIPTION'),
        },
        {
          background: categoryApparel4,
          link: `${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup`,
          caption: t('APPAREL_CATEGORY_4'),
          description: t('APPAREL_CATEGORY_4_DESCRIPTION'),
        },
        {
          background: categoryApparel5,
          link: `${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup`,
          caption: t('APPAREL_CATEGORY_5'),
          description: t('APPAREL_CATEGORY_5_DESCRIPTION'),
        },
        {
          background: categoryApparel6,
          link: `${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup`,
          caption: t('APPAREL_CATEGORY_6'),
          description: t('APPAREL_CATEGORY_6_DESCRIPTION'),
        },
        {
          background: categoryApparel7,
          link: `${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup`,
          caption: t('APPAREL_CATEGORY_7'),
          description: t('APPAREL_CATEGORY_7_DESCRIPTION'),
        },
        {
          background: categoryApparel8,
          link: `${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup`,
          caption: t('APPAREL_CATEGORY_8'),
          description: t('APPAREL_CATEGORY_8_DESCRIPTION'),
        },
      ],
    },
    crossCategorySlider: {
      className: 'swiper-cols-4 swiper-center',
      slidesPerView: 'auto',
      spaceBetween: 24,
      simulateTouch: true,
      pagination: {
        clickable: true,
        dynamicBullets: true,
        renderBullet: function (index, className) {
          return `<span class="${className}"><span class="swiper-pagination-bullet-default"></span></span>`
        },
      },
      navigation: true,
      slides: [
        {
          background: crossCategoryApparel1,
          description: t('APPAREL_CROSS_CATEGORY_1_DESCRIPTION'),
          caption: t('APPAREL_CROSS_CATEGORY_1'),
        },
        {
          background: crossCategoryApparel2,
          description: t('APPAREL_CROSS_CATEGORY_2_DESCRIPTION'),
          caption: t('APPAREL_CROSS_CATEGORY_2'),
        },
      ],
    },
    fourCriteria: {
      headers: [t('CRITERIA'), t('DEFINITION'), t('PROCEDURE')],
      rows: [
        {
          criteria: t('APPAREL_CRITERIA_1'),
          definition: t('APPAREL_CRITERIA_DEFINITION_1'),
          procedure: t('APPAREL_CRITERIA_PROCEDURE_1'),
        },
        {
          criteria: t('APPAREL_CRITERIA_2'),
          definition: t('APPAREL_CRITERIA_DEFINITION_2'),
          procedure: t('APPAREL_CRITERIA_PROCEDURE_2'),
        },
        {
          criteria: t('APPAREL_CRITERIA_3'),
          definition: t('APPAREL_CRITERIA_DEFINITION_3'),
          procedure: t('APPAREL_CRITERIA_PROCEDURE_3'),
        },
        {
          criteria: t('APPAREL_CRITERIA_4'),
          definition: t('APPAREL_CRITERIA_DEFINITION_4'),
          procedure: t('APPAREL_CRITERIA_PROCEDURE_4'),
        },
      ],
    },
    holisticCriteria: {
      headers: [],
      rows: [
        {
          criteria: t('APPAREL_CRITERIA_5'),
          definition: t('APPAREL_CRITERIA_DEFINITION_5'),
          procedure: t('APPAREL_CRITERIA_PROCEDURE_5'),
        },
      ],
    },
    smartCriteria: {
      headers: [],
      rows: [
        {
          criteria: t('APPAREL_CRITERIA_6'),
          definition: t('APPAREL_CRITERIA_DEFINITION_6'),
          procedure: t('APPAREL_CRITERIA_PROCEDURE_6'),
        },
      ],
    },
    benefitsTable: {
      headers: [t('BEST_PRODUCT'), t('TOP_TEN'), t('SELECTION')],
      rows: [
        {
          name: t('APPAREL_BENEFITS_1'),
          tooltip: t('APPAREL_BENEFITS_1_TOOLTIP'),
          best_product: true,
          top_ten: false,
          selection: false,
        },
        {
          name: t('APPAREL_BENEFITS_2'),
          tooltip: t('APPAREL_BENEFITS_2_TOOLTIP'),
          best_product: false,
          top_ten: true,
          selection: false,
        },
        {
          name: t('APPAREL_BENEFITS_3'),
          tooltip: t('APPAREL_BENEFITS_3_TOOLTIP'),
          best_product: false,
          top_ten: false,
          selection: true,
        },
        {
          name: t('APPAREL_BENEFITS_4'),
          tooltip: t('APPAREL_BENEFITS_4_TOOLTIP'),
          best_product: true,
          top_ten: false,
          selection: false,
        },
        {
          name: t('APPAREL_BENEFITS_5'),
          tooltip: t('APPAREL_BENEFITS_5_TOOLTIP'),
          best_product: false,
          top_ten: true,
          selection: false,
        },
        {
          name: t('APPAREL_BENEFITS_6'),
          tooltip: t('APPAREL_BENEFITS_6_TOOLTIP'),
          best_product: true,
          top_ten: false,
          selection: false,
        },
        {
          name: t('APPAREL_BENEFITS_7'),
          tooltip: t('APPAREL_BENEFITS_7_TOOLTIP'),
          best_product: true,
          top_ten: true,
          selection: false,
        },
        {
          name: t('APPAREL_BENEFITS_8'),
          tooltip: t('APPAREL_BENEFITS_8_TOOLTIP'),
          best_product: false,
          top_ten: false,
          selection: true,
        },
        {
          name: t('APPAREL_BENEFITS_9'),
          tooltip: t('APPAREL_BENEFITS_9_TOOLTIP'),
          best_product: true,
          top_ten: true,
          selection: true,
        },
        {
          name: t('APPAREL_BENEFITS_10'),
          tooltip: t('APPAREL_BENEFITS_10_TOOLTIP'),
          best_product: true,
          top_ten: true,
          selection: true,
        },
        {
          name: t('APPAREL_BENEFITS_11'),
          tooltip: t('APPAREL_BENEFITS_11_TOOLTIP'),
          best_product: true,
          top_ten: true,
          selection: true,
        },
        {
          name: t('APPAREL_BENEFITS_12'),
          tooltip: t('APPAREL_BENEFITS_12_TOOLTIP'),
          best_product: true,
          top_ten: true,
          selection: true,
        },
        {
          name: t('APPAREL_BENEFITS_13'),
          tooltip: t('APPAREL_BENEFITS_13_TOOLTIP'),
          best_product: true,
          top_ten: true,
          selection: true,
        },
        {
          name: t('APPAREL_BENEFITS_14'),
          tooltip: t('APPAREL_BENEFITS_14_TOOLTIP'),
          best_product: true,
          top_ten: true,
          selection: true,
        },
        {
          name: t('APPAREL_BENEFITS_15'),
          tooltip: t('APPAREL_BENEFITS_15_TOOLTIP'),
          best_product: true,
          top_ten: true,
          selection: true,
        },
        {
          name: t('APPAREL_BENEFITS_16'),
          tooltip: t('APPAREL_BENEFITS_16_TOOLTIP'),
          best_product: true,
          top_ten: true,
          selection: true,
        },
      ],
      last: [t('OVERVIEW_OF_COSTS'), t('BEST_PRODUCT_PRICE'), t('TOP_TEN_PRICE'), t('SELECTION_PRICE')],
    },
  }
}
